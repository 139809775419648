
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiShopDetail, apiShopAdd, apiShopEdit, apiSetMealLists } from '@/api/shop'
import historyMeal from './components/history-meal.vue'
import { debounce } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'

@Component({
  components: {
    historyMeal
  }
})
export default class ListsDetail extends Vue {
  /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    setMealData: any = []

    // 添加商城表单数据
    form: any = {
      name: '', // 名称
      super_admin: '', // 账号
      super_password: '', // 密码
      super_password_confirm: '', // 确认密码
      status: 1, // 商城状态 0-停止服务 1-开启服务
	  fee_status: 0, // 续费时间核对 0-未核对 1-已核对
      contact: '', // 联系人
      contact_mobile: '', // 联系人手机号
      domain_alias: '', // 域名别名
	  domain_alias_bk: '', // 备用域名别名
      is_check_domain: 1, // 是否验证域名
      shop_url: '', // 当前域名
      set_meal_id: '', // 套餐ID
      expires_time: '', // 到期时间
      remark: '', // 备注
      shop_data: {}
    };

    // 表单校验
    rules = {
      name: [{ required: true, message: '请输入商城名称', trigger: 'blur' }],
      super_admin: [
        { required: true, message: '请输入商城名称', trigger: 'blur' }
      ],
      super_password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        {
          validator: (rule: object, value: string, callback: any) => {
            !value ? callback(new Error('请输入密码')) : callback()
          },
          trigger: 'blur'
        }
      ],
      super_password_confirm: [
        { required: true, message: '请再次输入密码', trigger: 'blur' },
        {
          validator: (rule: object, value: string, callback: any) => {
            if (this.form.password) {
              if (!value) callback(new Error('请再次输入密码'))
              if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
            }
            callback()
          },
          trigger: 'blur'
        }
      ],
      set_meal_id: [{ required: true, message: '请选择套餐', trigger: ['blur', 'change'] }],
      expires_time: [{ required: true, message: '请选择到期时间', trigger: ['blur', 'change'] }]
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit (formName: string) {
      // 验证表单格式是否正确
      const refs = this.$refs[formName] as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) return

        if (!this.identity) {
          this.handleShopAdd()
        } else {
          this.handleShopEdit()
        }
      })
    }

    // 添加商城
    async handleShopAdd (): Promise<void> {
      await apiShopAdd({ ...this.form })
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑商城
    async handleShopEdit (): Promise<void> {
      await apiShopEdit({ ...this.form, id: this.identity })
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 获取详情
    async getShopDetailFunc (): Promise<void> {
      const res: any = await apiShopDetail({
        id: this.identity as number
      })
      Object.keys(res).map((key) => {
        this.$set(this.form, key, res[key])
      })
    }

    // 获取套餐列表
    async getSetMealLists (): Promise<void> {
      const { lists } = await apiSetMealLists({ status: 1 })
      this.setMealData = lists
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query

      // 编辑模式：初始化数据
      this.identity = +query.id
      if (+query.id) {
        this.getShopDetailFunc()
      }

      this.onSubmit = debounce(this.onSubmit, 2000)
      this.getSetMealLists()
    }
    /** E Life Cycle **/
}
